<template>
    <modal ref="modalRechazarSolicitudReserva" titulo="Rechazar solicitud" adicional="Rechazar" tamano="modal-md" @adicional="rechazar">
        <ValidationObserver ref="formRechazarReserva">
            <div class="row justify-content-center mx-0 py-3 f-15">
                <p>Hazle saber esto al residente.</p>
            </div>
            <div class="row justify-content-center mx-0 py-3">
                <ValidationProvider v-slot="{errors}" rules="required|max:350" name="comentario" tag="div" class="col-10">
                    <p class="f-12 pl-2 label-inputs">Comentario</p>
                    <el-input placeholder="Déjale un comentario al residente" v-model="inputComentario" class="w-100" type="textarea"  :autosize="{ minRows: 4, maxRows: 4}" />
                    <span class="text-danger f-10">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            inputComentario:'',
        }
    },
    methods:{
        toggle(){
            this.reset()
            this.$refs.modalRechazarSolicitudReserva.toggle()
        },
        guardarValores(){
            this.$refs.modalRechazarSolicitudReserva.toggle()
        },
        async rechazar(){
            let validate = await this.$refs.formRechazarReserva.validate()

            if(!validate) return this.notificacion('','Campo comentario no valido')

            this.$emit('rechazar',this.inputComentario)
        },
        reset(){
          this.inputComentario = ''
        }
    }
}
</script>